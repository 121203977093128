// Adopted and modified solution from Bohdan Didukh (2017)
// https://stackoverflow.com/questions/41594997/ios-10-safari-prevent-scrolling-behind-a-fixed-overlay-and-maintain-scroll-posi
let hasPassiveEvents = false;
if (typeof window !== 'undefined') {
    const passiveTestOptions = {
        get passive() {
            hasPassiveEvents = true;
            return undefined;
        },
    };
    const dummyListener = () => {
    };
    window.addEventListener('testPassive', dummyListener, passiveTestOptions);
    window.removeEventListener('testPassive', dummyListener);
}
const isSafariOnIpad = typeof window !== 'undefined' &&
    window.navigator &&
    window.navigator.userAgent.includes('Safari') &&
    window.navigator.userAgent.includes('iPad') &&
    !window.navigator.userAgent.includes('CriOS');
let locks = [];
let documentListenerAdded = false;
let initialClientY = -1;
let previousBodyOverflowSetting;
let previousBodyPaddingRight;
const allowTouchMove = (el) => locks.some(lock => {
    if (lock.options.allowTouchMove && lock.options.allowTouchMove(el)) {
        return true;
    }
    return false;
});
const preventDefault = (rawEvent) => {
    const e = rawEvent || window.event;
    if (allowTouchMove(e.target)) {
        return true;
    }
    if (e.touches.length > 1)
        return true;
    if (e.preventDefault)
        e.preventDefault();
    return false;
};
const setOverflowHidden = (options) => {
    if (previousBodyPaddingRight === undefined) {
        const reserveScrollBarGap = !!options && options.reserveScrollBarGap === true;
        const scrollBarGap = window.innerWidth - document.documentElement.clientWidth;
        if (reserveScrollBarGap && scrollBarGap > 0) {
            const computedBodyPaddingRight = parseInt(window.getComputedStyle(document.body).getPropertyValue('padding-right'), 10);
            previousBodyPaddingRight = document.body.style.paddingRight;
            document.body.style.paddingRight = `${computedBodyPaddingRight + scrollBarGap}px`;
        }
    }
    if (previousBodyOverflowSetting === undefined) {
        previousBodyOverflowSetting = document.body.style.overflow;
        document.body.style.overflow = 'hidden';
    }
};
const restoreOverflowSetting = () => {
    if (previousBodyPaddingRight !== undefined) {
        document.body.style.paddingRight = previousBodyPaddingRight;
        previousBodyPaddingRight = undefined;
    }
    if (previousBodyOverflowSetting !== undefined) {
        document.body.style.overflow = previousBodyOverflowSetting;
        previousBodyOverflowSetting = undefined;
    }
};
const isTargetElementTotallyScrolled = (targetElement) => targetElement ? targetElement.scrollHeight - targetElement.scrollTop <= targetElement.clientHeight : false;
const handleScroll = (event, targetElement) => {
    const clientY = event.targetTouches[0].clientY - initialClientY;
    if (allowTouchMove(event.target)) {
        return false;
    }
    if (targetElement && targetElement.scrollTop === 0 && clientY > 0) {
        return preventDefault(event);
    }
    if (isTargetElementTotallyScrolled(targetElement) && clientY < 0) {
        return preventDefault(event);
    }
    event.stopPropagation();
    return true;
};
export const disableBodyScroll = (targetElement, options) => {
    if (!targetElement) {
        console.error('disableBodyScroll unsuccessful - targetElement must be provided.');
        return;
    }
    if (locks.some(lock => lock.targetElement === targetElement)) {
        return;
    }
    const lock = {
        targetElement,
        options: options || {},
    };
    locks = [...locks, lock];
    setOverflowHidden(options);
    if (isSafariOnIpad) {
        targetElement.ontouchstart = (event) => {
            if (event.targetTouches.length === 1) {
                initialClientY = event.targetTouches[0].clientY;
            }
        };
        targetElement.ontouchmove = (event) => {
            if (event.targetTouches.length === 1) {
                handleScroll(event, targetElement);
            }
        };
        if (!documentListenerAdded) {
            document.addEventListener('touchmove', preventDefault, hasPassiveEvents ? { passive: false } : undefined);
            documentListenerAdded = true;
        }
    }
};
export const clearAllBodyScrollLocks = () => {
    locks.forEach((lock) => {
        lock.targetElement.ontouchstart = null;
        lock.targetElement.ontouchmove = null;
    });
    if (documentListenerAdded) {
        document.removeEventListener('touchmove', preventDefault);
        documentListenerAdded = false;
    }
    initialClientY = -1;
    restoreOverflowSetting();
    locks = [];
};
export const enableBodyScroll = (targetElement) => {
    if (!targetElement) {
        console.error('enableBodyScroll unsuccessful - targetElement must be provided.');
        return;
    }
    locks = locks.filter(lock => lock.targetElement !== targetElement);
    if (isSafariOnIpad) {
        targetElement.ontouchstart = null;
        targetElement.ontouchmove = null;
        if (documentListenerAdded && locks.length === 0) {
            document.removeEventListener('touchmove', preventDefault);
            documentListenerAdded = false;
        }
    }
    restoreOverflowSetting();
};
