import { Controller } from "@hotwired/stimulus";
import { Usercentrics } from "./utils/usercentrics";
export default class extends Controller {
    // @ts-ignore
    element;
    connect() {
        window.addEventListener("UC_UI_INITIALIZED", (e) => {
            this.setGoogleConsentMode();
        });
        window.addEventListener("consent_changed", (e) => {
            if (e.detail && e.detail.event == "consent_status") {
                this.setGoogleConsentMode();
            }
        });
    }
    setGoogleConsentMode() {
        if (!window.gtag) {
            return;
        }
        if (Usercentrics.hasConsent('googleanalytics4')) {
            // Google Analytics 4 aktivieren, wenn der Benutzer zugestimmt hat
            window.gtag('consent', 'update', {
                ad_user_data: 'granted',
                ad_personalization: 'granted',
                ad_storage: 'granted',
                analytics_storage: 'granted'
            });
        }
        else {
            // Google Analytics 4 deaktivieren, wenn der Benutzer nicht zugestimmt hat
            window.gtag('consent', 'update', {
                ad_user_data: 'denied',
                ad_personalization: 'denied',
                ad_storage: 'denied',
                analytics_storage: 'denied'
            });
        }
    }
    send(event) {
        const params = event.params || {};
        const type = event.type || "click";
        if (window._paq && params.name) {
            const target = params.target || window.location.href;
            let piece = params.piece;
            if (!piece && event.currentTarget.nodeName && type === "change") {
                piece = event.currentTarget.value;
            }
            window._paq.push(['trackContentInteraction', type, params.name, piece, target]);
            window._paq.push(['trackEvent', params.name, piece, params.target || '']);
        }
        if (window.hj) {
            window.hj('event', params.name);
        }
    }
    conversion(event) {
        const params = event.params || {};
        if (params.name) {
            if (window.gtag) {
                window.gtag("event", params.name, {
                    currency: "EUR",
                    value: 1
                });
            }
            if (window._paq) {
                switch (params.name) {
                    case "calenso":
                        window._paq.push(["trackGoal", 4]);
                        break;
                    case "telefon":
                        window._paq.push(["trackGoal", 5]);
                        break;
                    case "email":
                        window._paq.push(["trackGoal", 6]);
                        break;
                }
            }
            if (window.fbq) {
                window.fbq("trackCustom", params.name);
            }
            if (window.hj) {
                window.hj('event', 'conversion_' + params.name);
            }
        }
    }
}
